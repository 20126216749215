"use strict";

import 'animate.css';
import Swal from 'sweetalert2';

export default class App {

    static showAlert(message, type = 'success') {
        if (message == '') return;
    
        const title = type === 'success' ? 
        `<div class="flex gap-2"><i class="fa-solid fa-circle-check fa-xl text-blue-brand" style="line-height: normal;"></i><p>${message}</p></div>` : 
        `<div class="flex gap-2"><i class="fa-solid fa-circle-xmark fa-xl text-red-500" style="line-height: normal;"></i><p>${message}</p></div>`;
    
        Swal.fire({
            toast: true,
            position: 'top-end',
            title: title,
            showConfirmButton: false,
            timer: 3000,
            showClass: {
                popup: `
                  animate__animated
                  animate__fadeInDown
                  animate__faster
                `
              },
            hideClass: {
            popup: `
                animate__animated
                animate__fadeOutUp
                animate__faster
            `
            }
        });
    }

    // make sure that all popup stay within screen horizontally
    static correctPopupPosition(popupId) {
        let popup = $('#' + popupId);
    
        popup.removeAttr('style'); // to clean any calculation happend before
    
        let L = popup.offset().left; // the distance from the left side of the popup to the left side of the screen
        let PW = popup.outerWidth(); // the width of the popup
        let SW = $('body').width(); // the width of the screen
        let R = SW - (L + PW); // the distance from the right side of the popup to the right side of the screen
    
    
        /*
        1- (Math.abs(right) + width + Math.abs(left) - screen) this part will be grater than zero if part of the popup is outside the screen
        2- (Math.abs(screen)/screen) this part will check if popup is outside from the right or from the left and change the sign based on the side (negative mean right, positive mean left)
        3- adding the result to the left distance move the popup back into the screen, it will add or subtract from the left based on the result of (step 2)
        */
        let moveBy = ((Math.abs(R) + PW + Math.abs(L) - SW) / 2 * (Math.abs(R) / R)) + L
    
        popup.offset({ left: moveBy });
    
    }
}